import styleUtils from 'shared-components/styles/util.module.css'

/**
 * This component sets the background color of the content area (for cases with Playwire ads on the sides).
 * It is used only on SG for now
 */
const ContentBackground: React.FC = () => (
  <style
    dangerouslySetInnerHTML={{
      __html: `
    .${styleUtils.main} > .${styleUtils.wrapper} {
      background-color: rgb(var(--w));
    }
    `,
    }}
  />
)

export default ContentBackground
