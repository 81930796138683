import { publicConfig } from 'config'
import PlaywireRampScript from 'shared-components/ad/PlaywireRampScript'
import { useEffect } from 'react'
import { init } from 'shared-code/algolia-insights'
import AdBlockerScript from 'shared-components/ad/AdBlockerScript'
import AffilimateScript from 'shared-components/thirdparty/AffilimateScript'
import FunnelScript from 'shared-components/thirdparty/FunnelScript'
import GTM from 'shared-components/thirdparty/GTM'
import GtmGtag from 'shared-components/thirdparty/GtmGtag'
import OneSignalScript from 'shared-components/thirdparty/OnesignalScript'
import OptimizeScript from 'shared-components/thirdparty/OptimizeScript'
import SailthruTracker from 'shared-components/thirdparty/SailthruTracker'

const Thirdparty: React.VFC = () => {
  const sailthruId = publicConfig('sailThruCustomerId')

  const optimizeId = publicConfig('googleOptimizeId')

  const funnelId = publicConfig('funnelCustomerId')

  const onesignalId = publicConfig('oneSignalAppId')
  const onesignalDelay = publicConfig('onesignalLoadDelay')
  const onesignalLimit = publicConfig('oneSignalTagLimit')

  const adsDisabled = publicConfig('adsDisabled')
  const playwirePublisher = publicConfig('playwirePublisher')
  const playwireWebsite = publicConfig('playwireWebsite')

  const adBlockScript = publicConfig('adBlockerScript')

  const gtmId = publicConfig('gtmDesktopId')

  useEffect(() => {
    const algoliaAppId = publicConfig('algoliaAppId')
    const algoliaKeyId = publicConfig('algoliaKeyId')
    const algoliaSearchIndex = publicConfig('algoliaSearchIndex')

    if (algoliaAppId && algoliaKeyId && algoliaSearchIndex) {
      init(algoliaAppId, algoliaKeyId, algoliaSearchIndex)
    }
  }, [])

  const affilimateToken = publicConfig('affilimateToken')
  const gtagId = publicConfig('gtmGtagId')

  return (
    <>
      {affilimateToken ? <AffilimateScript token={affilimateToken} /> : null}
      {gtmId ? <GTM gtmId={gtmId} /> : null}

      {!adsDisabled && playwirePublisher && playwireWebsite ? (
        <PlaywireRampScript publisherId={playwirePublisher} websiteId={playwireWebsite} />
      ) : null}

      {sailthruId ? <SailthruTracker customerId={sailthruId} /> : null}
      {optimizeId ? <OptimizeScript optimizeId={optimizeId} /> : null}

      {funnelId ? <FunnelScript customerId={funnelId} /> : null}
      {onesignalId ? (
        <OneSignalScript appId={onesignalId} loadDelay={onesignalDelay} tagLimit={onesignalLimit} />
      ) : null}
      <AdBlockerScript blockScript={adBlockScript} />
      {gtagId ? <GtmGtag id={gtagId} /> : null}
    </>
  )
}

export default Thirdparty
