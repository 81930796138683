import clsx from 'clsx'
import React, { useCallback, useState } from 'react'
import { CommonDataContext } from 'shared-components/contexts/CommonDataContext'
import MobileNavTrigger from 'shared-components/layout/MobileNavTrigger'
import ThemeSwitcher from 'shared-components/layout/ThemeSwitcher'
import Linked from 'shared-components/service/Linked'
import DesktopNavPlaceholder from 'shared-components/layout/DesktopNavPlaceholder'
import styles from './Header.module.css'
import Logo from 'shared-svg/LogoSG.svg'
import Cart from 'shared-svg/Cart.svg'
import TopSearchPlaceholder from './service/TopSearchPlaceholder'
import { ScrollUpContext } from 'shared-components/contexts/ScrollUpContext'

const Header: React.VFC = () => {
  const [activated, setActivated] = useState(false)
  const activatedCb = useCallback(() => setActivated(true), [])
  const {
    common: { dealsButton, homeLink, dealsLink },
    showAdminPanel,
  } = CommonDataContext.useContainer()
  const { scrolledUp } = ScrollUpContext.useContainer()

  return (
    <header
      role="button"
      tabIndex={0}
      onClick={activatedCb}
      onKeyDown={activatedCb}
      className={clsx(styles.header, {
        [styles.headerActivated]: activated,
        [styles.preview]: showAdminPanel,
        [styles.scrolledUp]: scrolledUp,
      })}
    >
      <TopSearchPlaceholder />
      <DesktopNavPlaceholder />
      <Linked {...homeLink} className={styles.logoLink} aria-label="Home">
        <Logo className={styles.logoIcon} />
      </Linked>
      <Linked {...dealsLink} className={styles.dealsButton} aria-label="Home">
        {dealsButton.label}
        <Cart className={styles.dealsIcon} />
      </Linked>
      <ThemeSwitcher size="md" className={styles.themeSwitcher} />
      <MobileNavTrigger />
    </header>
  )
}

export default Header
