import { DSFacade } from 'types.sg.ds'
import { publicConfig } from 'config'
import { DSInitOptions } from 'shared-definitions/types'

export async function getds(options?: DSInitOptions): Promise<DSFacade> {
  const dataSourceType = publicConfig('dataSourceType')
  if (dataSourceType === 'wp') {
    const o = {
      ts: options?.ts,
      previewToken: options?.previewToken,
    } as const

    const mod = await import('ds/wp')
    return mod.default(o)
    // if (isServerSide()) {
    //   const mod = await import('data-source/wp')
    //   client = mod.default(o)
    // } else {
    //   const wwClient = wrap<DSWebWorker>(
    //     new Worker(new URL('data-source/wp-worker', import.meta.url))
    //   )
    //   await wwClient.init(o)
    //   client = wwClient
    // }
  }
  const mod = await import('ds/static')
  return mod.default
}
